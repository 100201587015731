import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import gamefiBull from "../../images/tronpedia/topic-pages/gamefi-bull.png";

const GameFi = ({data, location}) => {

    const pageData = {
        title: "GAMEFI",
        description: "Play-to-earn? Yeah… really. This section covers how GameFi makes this a possibility, and what it is likely to become the future of video games.",
        img: gamefiBull,
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia GAMEFI | TRONDAO</title>
                <meta name="description"
                      content="Consume, review and learn about the latest and greatest games using GameFi technology. Stay up to date on the technologies that will shape the future."/>
                <meta property="og:description"
                      content="Consume, review and learn about the latest and greatest games using GameFi technology. Stay up to date on the technologies that will shape the future."/>
                <meta property="og:title" content="TRONpedia GAMEFI | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={data} location={location} pageData={pageData}/>
        </>
    );
};


export default GameFi;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle (
    filter: {tronpedia_article_info: {category: {eq: "GAMEFI"}}}
  ) {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
           category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
